<app-menu-bar></app-menu-bar>

<p-panel header="Procedimentos Iniciais" [toggleable]="true" [collapsed]="true">
    <p-tabView>
        <p-tabPanel header="Acessar a plataforma">
            <tr>
                <td>
                    <img src="../../assets/images/PaginaInicial.jpg" class="ImagemPadrao">
                </td>
                <td>
                    <p>
                        Para acessar a plataforma, devera acessar atraves de um navegador (browser) o endereco https://conversor-tco.vetorit-solucoes.com.br
                    </p>
                </td>
            </tr>
        </p-tabPanel>
        <p-tabPanel header="Verificando a versao da aplicacao">
            <td>
                <img src="../../assets/images/VerificandoVersao.jpg" class="ImagemPadrao">             
            </td>
            <td>
                <p>
                    Na tela inicial (tela de login), no canto inferior a direita, e possivel visualizar a versao da aplicacao (incluindo as versoes dos componentes do produto).
                </p>
            </td>
        </p-tabPanel>
        <p-tabPanel header="Efetuar login">
            <td>
                <img src="../../assets/images/PaginaInicial.jpg" class="ImagemPadrao"><BR>
            </td>
            <td>
                <p>
                    Para efetuar o login na aplicacao, devera ser informado o usuario e a senha.
                </p>
                <p>
                    Apos o preenchimento dessas informacoes, clicar no botao Entrar.
                </p>
                <p>
                    Caso o login seja efetuado com sucesso, automaticamente sera redirecionado para a tela de processamento.
                </p>
            </td>
        </p-tabPanel>
        <p-tabPanel header="Menus disponiveis">
            <td>
                <img src="../../assets/images/Menus.jpg" class="ImagemPadrao"><BR>
            </td>
            <td>
                Uma vez que seja feito o login na aplicacao, estarao disponiveis os menus abaixo:<BR>
                <ol>
                    <li>Configuracoes - atraves desse menu o usuario podera configurar as companhias e estabelecimentos associando um codigo de empresa e um grupo contabil correspondente.</li>
                    <li>Grupos - atraves desse menu o usuario podera consultar os dados do grupo a que pertence. Tambem sera possivel fazer alguma manutencao dos dados.</li>
                    <li>Usuarios - atraves desse menu o usuario podera fazer a manutencao de usuarios, compreendendo a inclusao, alteracao e exclusao de usuarios.</li>
                    <li>Processamento - atraves desse menu o usuario podera acompanhar os processamentos em execucao, submeter um novo processamento e tambem consultar processamentos executados anteriormente.</li>
                    <li>Abrir novo ticket - atraves desse menu o usuario podera realizar a abertura de um ticket para reportar eventualmente uma ocorrência na aplicacao. So devem ser abertos ticket em se tratando de erros de aplicacao.</li>
                    <li>Ajuda - atraves desse menu o usuario podera esclarecer duvidas sobre o funcioanmento do produto.</li>
                </ol>            
            </td>
        </p-tabPanel>
    </p-tabView>  
</p-panel>

<p-panel header="Configurando código" [toggleable]="true" [collapsed]="true">
    <p-tabView>
        <p-tabPanel header="Acessar o menu Configuracoes">
            <td>
                <img src="../../assets/images/Configuracao1.jpg" class="ImagemPadrao">
            </td>
            <td>
                <p>
                    Atraves do menu principal, acessar o menu denominado Configurações.
                </p>
                <p>
                   Selecionar Códigos de Empresa/Estabelecimento.
                </p>
            </td>
        </p-tabPanel>
        <p-tabPanel header="Selecionar alteracao">
            <td>
                <img src="../../assets/images/Código.jpg" class="ImagemPadrao">
            </td>
            <td>
                <p>
                    Após a abertura da janela de configurações, selecionar companhia que deseja alterar, apagar ou reverter.
                </p>
            </td>
        </p-tabPanel>

        <p-tabPanel header="Funcionalidades">

            <td>
                <img src="../../assets/images/Código2.jpg" class="ImagemPadrao">
                <p>
                    Após fazer a alteração que deseja, clicar no icone de aplicar.
                </p>
                <p>
                    Caso queira reverter a ação é só clicar no icone de reverter.
                </p>
                <p>
                   E se for necessário deletar o registro da empresa, selecione o icone de limpar e confirme a ação .
                </p>
            </td>
          
        </p-tabPanel>
      
    </p-tabView>  
</p-panel>


<p-panel header="Criando uma programação de Carga" [toggleable]="true" [collapsed]="true">
    <p-tabView>
        <p-tabPanel header="Acessar o menu Processamento">
            <td>
                <img src="../../assets/images/Menus.jpg" class="ImagemPadrao">
            </td>
            <td>
                <p>
                    Atraves do menu principal, acessar o menu denominado Processamento.
                </p>
            </td>
        </p-tabPanel>
        <p-tabPanel header="Clicar no botao Adicionar">
            <td>
                <img src="../../assets/images/BotãoAdicionar.jpg" class="ImagemPadrao">
            </td>
            <td>
                <p>
                    Após a abertura da janela de prrocessamento, clicar no botao Adicionar.
                </p>
            </td>
        </p-tabPanel>
        <p-tabPanel header="Selecionar companhia desejada">

            <td>
                <img src="../../assets/images/ProgramacaoCarga.jpg" class="ImagemPadrao">
            </td>

            <td>
                <p>Seleciona companhia.</p>
                
                 <p> Após selecionar companhia, clicar no botão de salvar.</p>

            </td>
          
        </p-tabPanel>
    </p-tabView>  
</p-panel>

<p-panel header="Adicionando Arquivos" [toggleable]="true" [collapsed]="true">
    <p-tabView>
        <p-tabPanel header="Acessar o menu Processamento">
            <td>
                <img src="../../assets/images/Menus.jpg" class="ImagemPadrao">        
            </td>
            <td>
                <p>Atraves do menu principal, acessar o menu denominado Processamento.</p>
            </td>
        </p-tabPanel>
        <p-tabPanel header="Upload de Arquivos">
            <td>
                <img src="../../assets/images/Upload1.jpg" class="ImagemPadrao">
            </td>
            <td>
                <p>Dentro da lista de processamentos disponiveis, localizar o botão com icone de upload.</p>
                <p>Clicar no botao com icone de  upload.</p>
            </td>
        </p-tabPanel>

        <p-tabPanel header="Selecionar Arquivo">
            <td>
                <img src="../../assets/images/SelecionarArquivo.jpg" class="ImagemPadrao">
            </td>
            <td>
                <p>Clicar no botão Selecionar Arquivo.</p>
                <p>Levando em consideração que o layout deve ser o mesmo oficial da safx referente ao Mastersaf.</p>
            </td>
        </p-tabPanel>

        <p-tabPanel header="Escolha o Arquivo">
            <td>
                <img src="../../assets/images/Upload2.jpg" class="ImagemPadrao">
            </td>
            <td>
                <p>Seleciona o arquivo desejado.</p>
                <p>Clica em abrir.</p>
            </td>
        </p-tabPanel>
    </p-tabView>  
</p-panel>


<p-panel header="Executando Funcionalidades do Processamento de Carga " [toggleable]="true" [collapsed]="true">
<p-tabView>
    <p-tabPanel header="Acessar o menu Processamento">
     <td>
        <img src="../../assets/images/Menus.jpg" class="ImagemPadrao">        
         </td>
        <td>
            <p> Atraves do menu principal, acessar o menu denominado Processamento. </p>
    </p-tabPanel>

    <p-tabPanel header="Iniciar programação">
     <td>
         <img src="../../assets/images/Execucao1.jpg" class="ImagemPadrao">
         </td>
    <td>
            <p>Dentro da lista de processamentos disponiveis, localizar o botão com icone de iniciar programação.</p>
            <p>Clicar no icone de Iniciar programação.</p>
    </td>
    </p-tabPanel>

    <p-tabPanel header="Cancelar Execução">
     <td>
    <img src="../../assets/images/Execucao2.jpg" class="ImagemPadrao">
         </td>
         <td>
         <p>Dentro da lista de processamentos disponiveis, localizar o botão com icone de cancelar programação.</p>
        <p>Clicar no botão com icone de cancelar programação.</p>
        </td>
    </p-tabPanel>
    
                 
    <p-tabPanel header="Detalhamento do Processo">
             <td>
            <img src="../../assets/images/Execucao3.jpg" class="ImagemPadrao">
             </td>
                <td>
                <p>Dentro da lista de processamentos disponiveis, localizar o botão com icone de Log de Processamento.</p>
                <p>Clicar no botao com icone de  Log de processamento.</p>
                 </td>
    </p-tabPanel>

    <p-tabPanel header="Exportar Detalhamento">
                <td>
                <img src="../../assets/images/Exportar.jpg" class="ImagemPadrao">
                </td>
                <td>
                <p>Clicar no botão  Exportar Detalhamento.</p>
                <p> Visualizar detalhamento através do arquivo excel que o sistema vai disponibilizar diretamente na sua máquina.</p>
                </td>
    </p-tabPanel>
    </p-tabView>
    </p-panel>
                           
<p-panel header="Filtrando os dados apresentados na janela" [toggleable]="true" [collapsed]="true">
    <p-tabView>
        <p-tabPanel header="Acessar o menu Processamento">
            <td>
                <img src="../../assets/images/Menus.jpg" class="ImagemPadrao">        
            </td>
            <td>
                <p>Atraves do menu principal, acessar o menu denominado Processamento.</p>
                <p>No exemplo apresentado sera usado a tela de processamento, todavia o mesmo procedimentos e aplicavel a qualquer tela do portal.</p>             
            </td>
        </p-tabPanel>

        <p-tabPanel header="Escolher a coluna que se desejar filtrar">
            <td>
                <img src="../../assets/images/FiltrarColunas1.jpg" class="ImagemPadrao">        
            </td>
            <td>
                <p>Ao escolher a coluna que se deseja filtrar, bastar clicar no funil para que seja apresentado o quaadro de definiçao do filtro.</p>
                <p>Proximo dessa opcao, tambem e possivel mudar a ordenacao que esta sendo apresentado na tela, clicando diretamente nas setas para cima e para baixo.</p>
            </td>
        </p-tabPanel>

        <p-tabPanel header="Preencher os dados do filtro">
            <td>
                <img src="../../assets/images/FiltrarColunas2.jpg" class="ImagemPadrao">        
            </td>
            <td>
                <p>Preencher o valor que se deseja considerar no filtro. Adicionalmente as condicoes, exemplo "contenha", "igual a", "iniciado com" e assim por diante.</p>
                <p>Apos definido os argumentos para o filtro, clicar no botao Apply</p>
            </td>
        </p-tabPanel>
    </p-tabView>  
</p-panel>

<p-panel header="Liberando acesso para novos profissionais" [toggleable]="true" [collapsed]="true">
    <p-tabView>
        <p-tabPanel header="Acessar a manutenção de Usuários">
            <td>
                <img src="../../assets/images/Menus.jpg" class="ImagemPadrao">        
            </td>
            <td>
                <p>  Através do menu principal, acessar o menu denominado Usuario. </p>
            </td>
        </p-tabPanel>

        <p-tabPanel header="Listagem de Usuários">
            <td>
                <img src="../../assets/images/ManutencaoUsuario.jpg" class="ImagemPadrao">
            </td>

            <td>
                <p> Nessa janela, para iniciar a inclusão de um novo usuário, deve-se clicar no botão Adicionar. </p>    
            </td>
        </p-tabPanel>

        <p-tabPanel header="Preencher as informacoes do novo usuario">
            <td>
                <img src="../../assets/images/InclusaoUsuario.jpg" class="ImagemPadrao">
            </td>

            <td>
                <p> Nessa janela, o usuário conseguirá fazer o preenchimento das informações do usuário.</p>    
                <p> É obrigatório o preenchimento dos campos: Nome Usuário, Senha, Status, Acesso, Grupo e Email.</p>
            </td>

        </p-tabPanel>

        <p-tabPanel header="Gravando o usuário">
            <td>
                <img src="../../assets/images/InclusaoUsuario.jpg" class="ImagemPadrao">
            </td>
            <td>
                <p> Após o preenchimento dos campos, clicar no botão Salvar. </p>    
            </td>
        </p-tabPanel>
    </p-tabView>  
</p-panel>

<p-panel header="Alterando a senha de acesso" [toggleable]="true" [collapsed]="true">
    <p-tabView>
        <p-tabPanel header="Acessar o menu Usuario">
            <td>                
                <img src="../../assets/images/Menus.jpg" class="ImagemPadrao">        
            </td>
            <td>
                <p> Atraves do menu principal, acessar o menu denominado Usuario.</p>
            </td>        
        </p-tabPanel>

        <p-tabPanel header="Localizar o usuario que se deseja atualizar">
            <td>                
                <img src="../../assets/images/ManutencaoAlteracaoUsuario.jpg" class="ImagemPadrao">        
            </td>
            <td>
                <p>
                    Localizar o usuário que se deseja alterar. 
                </p>
                <p>
                    Na coluna Operações, clicar no primeiro botão para Editar o usuário.
                </p>
            </td>        
        </p-tabPanel>
    <p-tabPanel header="Alterar a informacao da senha">
            <td>                
                <img src="../../assets/images/AlteracaoUsuario.jpg" class="ImagemPadrao">        
            </td>
            <td>
                <p>  Ao selecionar um usuário, é possível alterar as suas informações. </p>
                <p>  É recomendado a alteração da senha inicial. </p>
                <p> Ao término das alterações necessárias, clicar no botão Atualizar. </p>
            </td>        
        </p-tabPanel>
    </p-tabView>  
</p-panel>

    <p-panel header="Abrindo um ticket para sinalizar uma ocorrência no produto" [toggleable]="true" [collapsed]="true">
    <p-tabView>
        <p-tabPanel header="Acessar o menu  Abrir novo ticket">
            <td>
                <img src="../../assets/images/Menus.jpg" class="ImagemPadrao">        
            </td>
            <td>
                <p> Atraves do menu principal, acessar o menu denominado Abrir Novo Ticket (Ocorrência).</p>
                <p> Tambem e possivel acessar diretamente a abertura de ticket atraves do link abaixo:<BR>
                    <a href="https://forms.monday.com/forms/2f0fe5da369d1aade97c123fdea98ee2?r=use1">link</a>
                </p>
                &nbsp;
            </td>        
        </p-tabPanel>

        <p-tabPanel header="Preencher os dados da Ocorrência">
            <td>
                <img src="../../assets/images/AberturaTicket.jpg" class="ImagemPadrao">
            </td>
            <td>
                <p>Preencher os dados referente a ocorrência. Devera ser inclusas todas as informacoes relevantes para analise e diagnostico do problema.</p>
                <p>Ao termino do preenchimento, clicar no botao Submit para a abertura do ticket.</p>
                <p>Atraves do email informado, poderemos entrar em contato para esclarecimentos da ocorrência aberta, caso nao seja suficiente simular o problema.</p>
                <p>Obs.: Nao deverao ser abertos tickets para problemas de dados. Para esses casos, deve-se criar um novo arquivo com os dados a serem corrigidos e re-enviar pelo processamento.</p>
            </td>
        </p-tabPanel>
    </p-tabView>  
</p-panel>
