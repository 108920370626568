import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IApiResponse } from '../modelo/PageProps';
import { Injectable } from '@angular/core';
import { ConfiguracaoCodigoEstabelecimentos } from '../modelo/ConfiguracaoCodigoEstabelecimentos';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })
export class ConfiguracaoCodigoEstabelecimentosService {

    apiUrl: string = `${environment.backendUrl}/configuracao/codigo-estabelecimentos`;

    constructor(private http: HttpClient) { }

    httpOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhZG1pbiIsImlzcyI6Im1pY2hlbC5wYWl2YSIsImlhdCI6MTY5NjUxNTI5NCwiZXhwIjoxNjk2NjAxNjk0fQ.nHh3lsmG0eghQAbk7ZFeERK5GpkaxEMMaXHNlMDCybnHHvik8tphxYlxrBiCkSvZTl52zabqmP5mBXujCxYTqw'
        }
    }

    getEstabelecimentosAll (codigoEmpresaId: number, pageNumber: number, pageSize: number){
        let searchParams = new HttpParams();
  
        searchParams = searchParams.append(`page`, String(pageNumber));
        searchParams = searchParams.append(`size`, String(pageSize));
  
        return this.http.get<IApiResponse>(`${this.apiUrl}?codigoEmpresaId=${codigoEmpresaId}`,
        { params: searchParams, headers: this.httpOptions.headers });
      }
  
      salvar(registro: ConfiguracaoCodigoEstabelecimentos): Observable<void> {
        return this.http.post<void>(`${this.apiUrl}`, registro, { headers: this.httpOptions.headers });
      }
  
      editar(registro: ConfiguracaoCodigoEstabelecimentos): Observable<void>{
        return this.http.put<void>(`${this.apiUrl}/${registro.id}`, registro, { headers: this.httpOptions.headers})
      }
        
      remover(id: number | undefined): Observable<void> {
        return this.http.delete<void>(`${this.apiUrl}/${id}`,  { headers: this.httpOptions.headers})
      }


}

