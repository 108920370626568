import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IApiResponse } from '../modelo/PageProps';
import { Processamento } from '../modelo/Processamento';
import { Observable } from 'rxjs';
import { ProcessamentoLista } from '../modelo/ProcessamentoLista';

@Injectable({
    providedIn: 'root'
  })

  export class ProcessamentoListaService {

    apiUrl: string = `${environment.backendUrl}/processamento`;

    httpOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJHcnVwbyBkZSBUZXN0ZSIsImlzcyI6InVzdWFyaW8udGVzdGUiLCJpYXQiOjE2OTYwMDkzMDYsImV4cCI6MTcwMzg3MTcwNn0.WSfSAzxJZ2UsDOQRwZsnf2F_g5ND8sd10ttfH4uFGYDvzEwh78OsK0cJZ2awjE44qH46D8LWfj5BuyBM168zAg'
        }
    }

    httpOptionsMultiPart = {
        headers: {
            //'Content-Type': 'application/json',
            'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJHcnVwbyBkZSBUZXN0ZSIsImlzcyI6InVzdWFyaW8udGVzdGUiLCJpYXQiOjE2OTYwMDkzMDYsImV4cCI6MTcwMzg3MTcwNn0.WSfSAzxJZ2UsDOQRwZsnf2F_g5ND8sd10ttfH4uFGYDvzEwh78OsK0cJZ2awjE44qH46D8LWfj5BuyBM168zAg'
        }
    }

    constructor(private http: HttpClient) { }

 

    getListas(arquivoId: String | undefined | null): Observable<ProcessamentoLista[]> {
        let searchParams = new HttpParams();
        return this.http.get<ProcessamentoLista[]>(`${this.apiUrl}/lista/${arquivoId}`,
          { headers: this.httpOptions.headers });
      }  
    
    getExportacoes ( listaId: number | undefined): Observable<Blob>{
        let searchParams = new HttpParams();

        return this.http.post<any>(`${this.apiUrl}/lista`, listaId,
        { responseType: 'blob' as 'json' });
    }


  }
  