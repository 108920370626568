import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IApiResponse } from '../modelo/PageProps';
import { Processamento } from '../modelo/Processamento';
import { Observable } from 'rxjs';
import { ProcessamentoDocumentos } from '../modelo/ProcessamentoDocumentos';

@Injectable({
    providedIn: 'root'
  })

  export class ProcessamentoDocumentosService {

    apiUrl: string = `${environment.backendUrl}/processamento`;

    httpOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJHcnVwbyBkZSBUZXN0ZSIsImlzcyI6InVzdWFyaW8udGVzdGUiLCJpYXQiOjE2OTYwMDkzMDYsImV4cCI6MTcwMzg3MTcwNn0.WSfSAzxJZ2UsDOQRwZsnf2F_g5ND8sd10ttfH4uFGYDvzEwh78OsK0cJZ2awjE44qH46D8LWfj5BuyBM168zAg'
        }
    }

    httpOptionsMultiPart = {
        headers: {
            //'Content-Type': 'application/json',
            'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJHcnVwbyBkZSBUZXN0ZSIsImlzcyI6InVzdWFyaW8udGVzdGUiLCJpYXQiOjE2OTYwMDkzMDYsImV4cCI6MTcwMzg3MTcwNn0.WSfSAzxJZ2UsDOQRwZsnf2F_g5ND8sd10ttfH4uFGYDvzEwh78OsK0cJZ2awjE44qH46D8LWfj5BuyBM168zAg'
        }
    }

    constructor(private http: HttpClient) { }

 

    getDocumentos(listaId: String | undefined | null): Observable<ProcessamentoDocumentos[]> {
        let searchParams = new HttpParams();
        return this.http.get<ProcessamentoDocumentos[]>(`${this.apiUrl}/documentos/${listaId}`,
          { headers: this.httpOptions.headers });
      }  

  }
  