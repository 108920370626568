import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IApiResponse } from '../modelo/PageProps';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })
export class CompanhiaService {
    apiUrl: string = `${environment.backendUrl}/companhia`;

    constructor(private http: HttpClient) { }

    httpOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhZG1pbiIsImlzcyI6Im1pY2hlbC5wYWl2YSIsImlhdCI6MTY5NjUxNTI5NCwiZXhwIjoxNjk2NjAxNjk0fQ.nHh3lsmG0eghQAbk7ZFeERK5GpkaxEMMaXHNlMDCybnHHvik8tphxYlxrBiCkSvZTl52zabqmP5mBXujCxYTqw'
        }
    }

    getCompanhias (){
        let searchParams = new HttpParams();
  
        return this.http.get<IApiResponse>(`${this.apiUrl}`,
        { params: searchParams, headers: this.httpOptions.headers });
      }
  
}